<template>
    <section class="main">
      <div class="container pt-5 pb-5">
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 cursor-pointer"  @click="Filtro('concluido')">
            <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-active': selectType === 'concluido'}">
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                  <div class="d-flex flex-row justify-content-between w-100">
                    <p>Total concluídos</p> 
                  </div>
                  <div v-if="totalChamados > 0">
                    <div class="spinner-grow text-secondary" role="status" v-if="loading">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <h2 class="tertiary" v-if="!loading">{{ totalChamados }}</h2>
                  </div>
                  <h5 v-else>
                    <h2 class="tertiary" v-if="!loading">0</h2>
                    <small v-if="!loading">Nenhum chamado concluído</small>
                    <div class="spinner-grow" role="status" v-else>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </h5>
                </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 cursor-pointer" @click="Filtro('pendente')">
            <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-active': selectType === 'pendente'}">
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p class="mutted">Chamados pendentes</p>
                </div>
                <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div></h2>
                <h2 v-else class="tertiary">{{ totalPendentes }}</h2>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 cursor-pointer"  @click="Filtro('andamento')">
            <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-active': selectType === 'andamento'}">
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Chamados em andamento</p>
                </div>
                <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div></h2>
                <h2 v-else class="tertiary">{{ totalAndamento }}</h2>
              </div>
            </div>
          </div> 
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 cursor-pointer" @click="Filtro('new')">
            <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-active': selectType === 'new'}">
              <div class="card-body d-flex justify-content-between align-items-start flex-column">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Novos chamados</p>
                </div>
                <h2 v-if="loading"><div class="spinner-grow text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div></h2>
                <h2 v-else class="tertiary">{{ totalNew }}</h2>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
        <div class="col-12 text-left">
          <div class="row">
            <div class="form-row form_padrao  col-md-3">
                <div class="flutuante col-md-12 position-relative">
                  <select class="form-control"  @change="Filtro(selectType)" v-model="selectedYear">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>
              <div class="form-row form_padrao  col-md-3">
                <div class="flutuante col-md-12 position-relative">
                  <select class="form-control" v-model="typegraf">
                    <option value="line">Gráfico de Linha</option>
                    <option value="bar">Gráfico de Barra</option>
                  </select>
                </div>
              </div>
          </div>

        </div>
        <div class="col-12">
          <apexchart :type="typegraf" :options="chartOptions" :series="chartSeries"></apexchart>
        </div>
      </div>
        <!--
        <div class="row">
          <div class="col pt-5 d-md-flex d-inline text-center justify-content-between">
            <h2>Meus cursos ativos</h2>
            <router-link
              :to="{ name: 'addcurso' }"
              class="btn btn-tertiary mt-3 mt-md-0"
              >+ Publicar novo curso</router-link
            >
          </div>
        </div>-->
      </div>
      <Dialog header="Cadastro realizado com sucesso!" :visible.sync="EditPassword" :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '80vw' }" :style="{ width: '80vw' }">
          <div class="card">
            <div class="card-header text-center bg-warning">
              <h4>Cadastro realizado com sucesso!</h4>
            </div>
            <div class="card-body text-center">
              <p style=" text-align: justify; ">
                Olá {{ $store.state.user.name }},
                <br>
                Seja bem-vindo à plataforma de atendimento!
                <br>
                <br>
                Para garantir a segurança dos seus dados, foi gerada uma senha automática para a sua conta. Recomendamos que você altere essa senha para uma de sua escolha o quanto antes. Para fazer isso, basta clicar no botão abaixo e seguir as instruções em sua conta.
              </p>
              <router-link role="tab" :to="{name: 'configuracoes'}" class="btn btn-primary btn-lg mb-2 mt-2">Alterar senha</router-link>
              <p style=" text-align: justify; ">
                Lembre-se de escolher uma senha forte e segura que inclua letras maiúsculas e minúsculas, números e caracteres especiais de no mínimo 8 caracteres. Não compartilhe a sua senha com ninguém e atualize-a regularmente.
                <br>
                Se você tiver alguma dúvida ou precisar de ajuda, nossa equipe de suporte está à disposição para ajudá-lo.
              </p>
            </div>
          </div>
      </Dialog>
    </section>
  </template>
  <script>
  import Dialog from "primevue/dialog";
  import VueApexCharts from 'vue-apexcharts'
  export default {
    name: "HomeAdmin",
    components: {
    'apexchart': VueApexCharts,
      Dialog
    },
    data() {
      return {
        chartOptions: {
        chart: {
          id: 'quantidade-por-mes',
        },
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        },
        yaxis: {
          max: 0
        }
      },
      chartSeries: [{
        name: 'Quantidade',
        data: [0, 3, 0, 0, 0, 6, 0, 0, 0, 3, 0, 12]
      }],
      typegraf:'line',
      currentYear: new Date().getFullYear(), // Pega o ano atual
      selectedYear: new Date().getFullYear(),
      selectedOption:'mensal',
      selectType:'concluido',
      dataGraf:[],

        loading: false,
        totalChamados:0,
        totalPendentes:0,
        totalAndamento:0,
        totalNew:0,
        EditPassword:false,
      };
    },
    computed: {
      group() {
        return this.$store.state.user.group;
      },
      years() {
        let startYear = 2015;
        let years = [];
        for (let year = startYear; year <= this.currentYear; year++) {
          years.push(year);
        }
        return years;
      }
    },
  
    methods: {
      getRelatorios() {
        this.$http
          .get("/api/whitelabel/relatorios")
          .then((response) => 
          {
            this.totalChamados  = response.data["totalChamados"];
            this.totalPendentes = response.data["totalPendentes"];
            this.totalAndamento = response.data["totalAndamento"];
            this.totalNew       = response.data["totalNew"];

            this.chartSeries = [{
              name: 'Chamados',
              data: response.data['graf']['concluido']
            }];
            
            this.chartOptions = {
              yaxis:{
                max: (response.data['graf']['concluido'].reduce((max, atual) => Math.max(max, atual), response.data['graf']['concluido'][0])*2)
              }       
            }
          })
          .catch(() => {});
      },
      Filtro(selectType) {
      this.selectType = selectType;
      this.$http
        .post("/api/whitelabel/FiltreCount", { data: 
          { 
            periodo:'mensal',//this.selectedOption,
            status:this.selectType,
            selectedYear:this.selectedYear
            //startDate:this.dataInicial,
            //endDate:this.dataFinal
          } 
          })
        .then((response) => {
          this.chartSeries = [{
            name: 'Chamados',
            data: response.data
          }];
          this.chartOptions = {
                yaxis:{
                  max: (response.data.reduce((max, atual) => Math.max(max, atual), response.data[0])*2)
                }       
              }

        })
        .catch(() => {
          this.loading = false;
        });
    },
    },
    mounted() {
      if(this.$store.state.user.text_password != '********'){
        this.EditPassword = true;
      }
      this.getRelatorios();
    },
  };
  </script>
  <style lang="scss" scoped>
  .card {
    position: relative;
    min-height: 140px;
  }

.bg-active {
  background-color: var(--primary); /* Substitua 'green' pela cor desejada */
}
.bg-active > div > div > p, .bg-active > div > div > h2, .bg-active > div > div > h5, .bg-active > div > h2, .bg-active > div > h5, .bg-active > div > h2, .bg-active > div > h5 > h2{
  color:white;
}
  </style>