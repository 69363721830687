<template>
  <section class="main">
    <div class="container mt-2 pb-5">
      <div class="row">
        <div class="col-md-4 cursor-pointer" @click="Filtro('whitelabel')">
          <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-whitelabel': selectType === 'whitelabel'}">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p class="mutted">Total de White Labels</p>
              </div>
              <h2 v-if="loading">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </h2>
              <h2 v-else class="tertiary">{{ tdwl }}</h2>
            </div> 
          </div>
        </div>
        <div class="col-md-4 cursor-pointer"  @click="Filtro('atendente')">
          <div class="card mt-3 mt-md-0 border-0 position-relative" :class="{'bg-atendente': selectType === 'atendente'}">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Total de Atendentes</p>
              </div>
              <h2 v-if="loading">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </h2>
              <h2 v-else class="tertiary">{{ tda }}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Total Modelos Chat</p>
              </div>
              <h2 v-if="loading">
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </h2>
              <h2 v-else class="tertiary">{{ tdchat }}</h2>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12 text-left">
          <div class="row">
            <div class="form-row form_padrao  col-md-3">
                <div class="flutuante col-md-12 position-relative">
                  <select class="form-control"  @change="Filtro(selectType)" v-model="selectedYear">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>
              <div class="form-row form_padrao  col-md-3">
                <div class="flutuante col-md-12 position-relative">
                  <select class="form-control" v-model="typegraf">
                    <option value="line">Gráfico de Linha</option>
                    <option value="bar">Gráfico de Barra</option>
                  </select>
                </div>
              </div>
            <!--
              <div class="form-row form_padrao  col-md-3">
                <div class="flutuante col-md-12 position-relative">
                  <select class="form-control"v-model="selectedOption">
                    <option value="mensal">Mensal</option>
                    <option value="semanal">Semanal</option>
                  </select>
                </div>
              </div>
              <div class="form-row form_padrao   col-md-3">
                <div class="form-group flutuante col-md-12 position-relative">
                  <input type="date" name="pesquisar" id="date" v-model="dataInicial" required />
                  <label for="dataInicial">Criação inicial</label>
                </div>
              </div>
              
              <div class="form-row form_padrao   col-md-3">
                <div class="form-group flutuante col-md-12 position-relative">
                  <input type="date" name="pesquisar" id="date" v-model="dataFinal" required />
                  <label for="dataInicial">Criação final</label>
                </div>
              </div>
              
              <div class="col-3">
                <button class="btn btn-primary btn-sm" @click="Filtro" :disabled="dataInicial.length == 0 || dataFinal.length == 0 ">
                    <span>Filtrar</span>
                  </button>
              </div>
              -->
          </div>

        </div>
        <div class="col-12">
          <apexchart :type="typegraf" :options="chartOptions" :series="chartSeries"></apexchart>
        </div>
      </div>

    </div>
    <Dialog header="Cadastro realizado com sucesso!" :visible.sync="EditPassword" :modal="true"
      :breakpoints="{ '960px': '75vw', '640px': '80vw' }" :style="{ width: '80vw' }">
      <div class="card">
        <div class="card-header text-center bg-warning">
          <h4>Cadastro realizado com sucesso!</h4>
        </div>
        <div class="card-body text-center">
          <p style=" text-align: justify; ">
            Olá {{ $store.state.user.name }},
            <br>
            Seja bem-vindo à plataforma de atendimento!
            <br>
            <br>
            Para garantir a segurança dos seus dados, foi gerada uma senha automática para a sua conta. Recomendamos que
            você altere essa senha para uma de sua escolha o quanto antes. Para fazer isso, basta clicar no botão abaixo e
            seguir as instruções em sua conta.
          </p>
          <router-link role="tab" :to="{ name: 'configuracoes' }" class="btn btn-primary btn-lg mb-2 mt-2">Alterar
            senha</router-link>
          <p style=" text-align: justify; ">
            Lembre-se de escolher uma senha forte e segura que inclua letras maiúsculas e minúsculas, números e caracteres
            especiais de no mínimo 8 caracteres. Não compartilhe a sua senha com ninguém e atualize-a regularmente.
            <br>
            Se você tiver alguma dúvida ou precisar de ajuda, nossa equipe de suporte está à disposição para ajudá-lo.
          </p>
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
import Dialog from "primevue/dialog";
import Produtos from "@/components/ProdutosLista";
import VueApexCharts from 'vue-apexcharts'
export default {
  name: "HomeAtendente",
  components: {
    Produtos,
    Dialog,
    'apexchart': VueApexCharts
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'quantidade-por-mes',
        },
        xaxis: {
          categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        },
        yaxis: {
          max: 0
        }
      },
      chartSeries: [{
        name: 'Quantidade',
        data: [0, 3, 0, 0, 0, 6, 0, 0, 0, 3, 0, 12]
      }],
      typegraf:'line',
      currentYear: new Date().getFullYear(), // Pega o ano atual
      selectedYear: new Date().getFullYear(),
      dataInicial:'',
      dataFinal:'',
      selectedOption:'mensal',
      selectType:'whitelabel',
      dataGraf:[],

      loading: true,
      tdwl: 0,
      tda: 0,
      tdchat: 0,
      EditPassword: false
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
    years() {
      let startYear = 2015;
      let years = [];
      for (let year = startYear; year <= this.currentYear; year++) {
        years.push(year);
      }
      return years;
    }
  },

  methods: {
    getRelatorios() {
      this.$http
        .get("/api/gestor/contlist")
        .then((response) => {
          this.tdwl = response.data["tdwl"];
          this.tda = response.data["tda"];
          this.tdchat = response.data["tdchat"];
          
          this.dataGraf = response.data['graf'];
          
          this.chartSeries = [{
            name: 'Cadastro',
            data: this.dataGraf["whitelabel"]["mensal"]
          }];
          
          this.chartOptions = {
            yaxis:{
              max: (this.dataGraf["whitelabel"]["mensal"].reduce((max, atual) => Math.max(max, atual), this.dataGraf["whitelabel"]["mensal"][0])*2)
            }       
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Filtro(selectType) {
      this.selectType = selectType;
      this.$http
        .post("/api/gestor/FiltreCount", { data: 
          { 
            periodo:'mensal',//this.selectedOption,
            type:this.selectType,
            selectedYear:this.selectedYear
            //startDate:this.dataInicial,
            //endDate:this.dataFinal
          } 
          })
        .then((response) => {
          this.chartSeries = [{
            name: 'Cadastro',
            data: response.data
          }];
          this.chartOptions = {
                yaxis:{
                  max: (response.data.reduce((max, atual) => Math.max(max, atual), response.data[0])*2)
                }       
              }

        })
        .catch(() => {
          this.loading = false;
        });
    },

  },
  mounted() {
    if(this.$store.state.user.text_password != undefined){
      if (this.$store.state.user.text_password != '********') {
        this.EditPassword = true;
      }
    }
    this.getRelatorios();
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}

.btn-primary {
  box-shadow: 0 3px 16px #0000001a;
  padding: 0.7rem 3rem;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: .9rem;
  transition: all .2s ease-in-out;
  color: white;
}
.bg-whitelabel {
  background-color: var(--primary); /* Substitua 'blue' pela cor desejada */
}
.bg-atendente {
  background-color: var(--primary); /* Substitua 'green' pela cor desejada */
}
.bg-whitelabel > div > div > p, .bg-whitelabel > div > h2,
.bg-atendente > div > div > p, .bg-atendente > div > h2{
  color:white;
}
</style>